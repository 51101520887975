body{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

input[type=button] {
    margin: 6px;
    padding: 6px;
    cursor: pointer;
}

input[type=button]:disabled {
    cursor: unset;
}